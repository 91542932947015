body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.metadata.metadata--subTitle{
  opacity: 0;
}

.custom-link{
  opacity: 0;
}

.metadata.metadata.metadata--title{
  position: relative;
  top: 12%;
  left: 0.25%;
}

img{
  position: relative;
  left: 0.375%;
}